<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :height="imgHeight+'px'" :interval="5000" style="z-index: -1;">
			<el-carousel-item v-for="(item,index) in bannerlist" :key="index">
				<img ref="imgHeight" style="width:100%;" :src="item.imageurl" mode="widthFix" />
			</el-carousel-item>
		</el-carousel>
		<!-- 产品优势 -->
		<div class="title">产品优势</div>
		<div class="advantage">
			<div class="itembox">
				<div class="item fcs" v-for="(item,index) in advantagelist" :key="index">
					<img :src="item.imageurl" alt="">
					<div style="width: 224px;">
						<div class="t">{{item.title}}</div>
						<div class="p">{{item.describe}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 应用场景825 -->
		<div class="title">应用场景</div>
		<div class="scene fcs">
			<div class="btnbox">
				<div class="btn fcc">找回密码</div>
				<div class="btn fcc">支付认证</div>
				<div class="btn fcc">用户登录</div>
			</div>
			<img src="../assets/image/changjing.png" alt="">
			<div class="btnbox">
				<div class="btn fcc">身份验证</div>
				<div class="btn fcc">登录异常</div>
				<div class="btn fcc">用户注册</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgHeight: '', //图片高度
				bannerlist: [], //轮播图列表
				advantagelist: []
			}
		},
		mounted() {
			this.getbanner();
			// 监听窗口变化，使得轮播图高度自适应图片高度
			window.addEventListener("resize", () => {
				this.getbanner();
			}, false);
		},
		destroyed() { //切换页面后销毁监听窗口事件
			window.removeEventListener("resize", this.getbanner, false);
		},
		created() {
			this.getadvantagelist();

		},
		methods: {
			//获取轮播图
			getbanner() {
				let data = {
					mark: 'introduce01'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.bannerlist = res.data.data
						//获取到图片数据之后
						this.$nextTick(function() {
							// 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
							this.imgHeight = document.body.clientWidth * 500 / 1920
						});
					}
				})
			},
			//获取产品优势
			getadvantagelist() {
				let data = {
					mark: 'index02'
				}
				this.$api.home.getAdsByMark(data).then(res => {
					if (res.code == 1) {
						this.advantagelist = res.data.data
					}
				})
			}
		}
	}
</script>

<style scoped>
	.title {
		width: 200px;
		margin: 50px auto;
		font-size: 32px;
		text-align: center;
	}

	.advantage {
		width: 1200px;
		margin: 0 auto;
	}

	.advantage .itembox {
		width: 1200px;
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;
	}

	.advantage .itembox .item {
		width: 340px;
		background: #ffffff;
		margin: 20px 29px;
		box-sizing: border-box;
		font-size: 16px;
		cursor: pointer;
	}

	.advantage .itembox .item img {
		width: 80px;
		display: block;
	}

	.advantage .itembox .item .t {
		font-size: 22px;
		color: #24272C;
		margin-bottom: 10px;
	}

	.advantage .itembox .item .p {
		font-size: 18px;
		color: #24272C;
		opacity: 0.8;
	}

	.scene {
		width: 1025px;
		margin: 0 auto;
		margin-bottom: 100px;
	}

	.scene .btnbox {}

	.scene .btnbox .btn {
		width: 200px;
		height: 64px;
		border-radius: 45px;
		border: 1px solid #006DFF;
		font-size: 16px;
		color: #006DFF;
		margin: 40px 0;
		cursor: pointer;
	}

	.scene .btnbox .btn:hover {
		background: #006DFF;
		color: #ffffff;
	}

	.scene img {
		width: 477px;
		height: 488px;
	}
</style>